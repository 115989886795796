import * as Sentry from "@sentry/browser";

import fetchAPI, { getData, getDataDependencies } from "../lib/api";
import { isEmpty, last } from "lodash";

import BlockManager from "../components/shared/blockManager";
import { Flex } from "@chakra-ui/react";
import delve from "dlv";
import { useRouter } from "next/router";

interface IPageData {
  id: number;
  slug: string;
}
interface Props {
  pageData: IPageData;
}

const Universals = ({ pageData }: Props) => {
  const router = useRouter();
  if (router.isFallback) {
    return <div>Loading...</div>;
  }

  const blocks = delve(pageData, "block");
  return (
    <Flex
      as="section"
      w="full"
      flexDir="column"
      className={`template-page page-id-${pageData.id} page-slug-${
        pageData.slug !== "" ? pageData.slug : "home"
      }`}
    >
      <BlockManager blocks={blocks} />
    </Flex>
  );
};

export async function getStaticPaths() {
  const allPages = await fetchAPI(
    "/paginas?slug_nin=produtos&slug_nin=contato&slug_nin=conteudo"
  );
  // if (allPages.statusCode == 403) return { paths: [], fallback: true };
  const params = allPages.map((pages: any) => {
    return {
      params: {
        slug: [pages.slug],
      },
    };
  });

  return {
    paths: params,
    fallback: "blocking",
  };
}

export async function getStaticProps({ params }: any) {
  const slug: string = isEmpty(params.slug) ? "" : String(last(params.slug));
  // if (slug === "") return { notFound: true };
  // try {
  const data = getData(slug, "pt-BR");
  const res = await fetch(delve(data, "data"));
  const json = await res.json();

  if (!json.length) {
    return { notFound: true };
  }

  const pageData = await getDataDependencies(delve(json, "0"));

  return {
    props: { pageData },
    revalidate: 30,
  };
  // } catch (error) {
  //   // console.log('Erro test:', error);
  //   // Sentry.captureException(error);
  //   return {
  //     notFound: true,
  //   };
  // }
}

export default Universals;
